import { ApiCall_GET, ApiCall_POST } from "..";

export const getByPhoneMessages = async (_token, _phone) => {
  const response = await ApiCall_GET(`/whapi/getbyphonemessages`, _token, { phone: _phone });
  return response;
}

export const postMessage = async (_token, _phone, _message) => {
  const response = await ApiCall_POST(`/whapi/postmessage`, { phone: _phone, message: _message }, _token);
  return response;
}

export const clearUnreadMessages = async (_token, _id_user_owner, _id_user_repairshop) => {
  const response = await ApiCall_POST(`/whapi/deletewhatsappnotification`, { id_user_owner: _id_user_owner, id_user_repairshop: _id_user_repairshop }, _token);
  return response;
}