import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NoInterventionsComponent = () => {
  const navigate = useNavigate();
  return (
    <Stack sx={{ height: "100vh", alignItems: "center", justifyContent: "flex-start", maxWidth: "700px" }}>
      <Typography sx={{ textAlign: "center", fontWeight: "100" }} variant="h2">
        No hay intervenciones registradas
      </Typography>
      <Typography sx={{ fontSize: "1.5rem", textAlign: "center", fontWeight: "200", padding: "1em 0 0.5em 0", color: "#6f6f6f" }}>De momento.</Typography>
      <Button
        sx={{ marginTop: "20px" }}
        variant="contained"
        onClick={() => {
          navigate("/intervencion/crear");
        }}
      >
        Crear intervención
      </Button>
    </Stack>
  );
};

export default NoInterventionsComponent;
