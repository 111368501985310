import { Autocomplete, Button, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import StraightenIcon from "@mui/icons-material/Straighten";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import SidebarCarcomponent from "./SidebarCarcomponent.Component";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker from "react-multi-date-picker";
import transition from "react-element-popper/animations/transition";
import opacity from "react-element-popper/animations/opacity";
import "./OrangeDatePicker.css";
const SidebarComponent = ({ vehicle, userOwner, intervention, allModels, allManufacturers, modifiedCarcomponents, setUserOwnerState, setVehicleState, setInterventionState, isConfirmed }) => {
  // const isEditModeTrue = () => {
  //   return (
  //     !vehicle?.plate || !vehicle?.id_manufacturer || !vehicle?.id_model || !vehicle?.plate || !vehicle?.km || !userOwner?.name_first || !userOwner?.name_last || !intervention?.created_at || !vehicle?.date_nextitv
  //   );
  // };

  const [editMode, setEditMode] = useState(false);
  const [tempPlate, setTempPlate] = useState();
  const [tempKm, setTempKm] = useState();
  const [tempName, setTempName] = useState();
  const [tempSurname, setTempSurname] = useState();
  const [tempDate, setTempDate] = useState();
  const [tempManufacturer, setTempManufacturer] = useState();
  const [tempModel, setTempModel] = useState();
  const [tempItv, setTempItv] = useState();

  const weekDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  useEffect(() => {
    setTempManufacturer(allManufacturers?.find((manufacturer) => manufacturer?.id === vehicle?.id_manufacturer) || null);
    setTempModel(allModels?.find((model) => model?.id === vehicle?.id_model) || null);
    setTempPlate(vehicle?.plate || null);
    setTempKm(formatKm(vehicle?.km) || null);
    setTempName(userOwner?.name_first || null);
    setTempSurname(userOwner?.name_last || null);
    setTempDate(new Date(intervention?.created_at) || new Date(now));
    setTempItv(vehicle?.date_nextitv ? new Date(vehicle?.date_nextitv) : null);
  }, [editMode]);

  const isButtonDisabled = () => {
    return !tempPlate || !tempKm || tempKm === 0 || tempKm === "0" || !tempName || !tempSurname || !tempDate || !tempManufacturer || !tempModel;
  };

  const handleManufacturerChange = (event, newManufacturer) => {
    setTempManufacturer(newManufacturer);
    setTempModel(null);
  };

  const handleSave = () => {
    setUserOwnerState((prevUserOwner) => {
      const updates = {};

      if (tempName && tempName !== prevUserOwner.name_first) {
        updates.name_first = tempName;
      }

      if (tempSurname && tempSurname !== prevUserOwner.name_last) {
        updates.name_last = tempSurname;
      }

      return { ...prevUserOwner, ...updates };
    });

    setInterventionState((prevInterventionState) => {
      const updates = {};

      if (tempDate && tempDate !== prevInterventionState.created_at) {
        updates.created_at = tempDate;
      }

      return { ...prevInterventionState, ...updates };
    });

    setVehicleState((prevVehicleState) => {
      const updates = {};

      if (tempKm && tempKm !== prevVehicleState.km) {
        updates.km = parseInt(tempKm.replace(/\./g, ""));
      }

      if (tempPlate && tempPlate !== prevVehicleState.plate) {
        updates.plate = tempPlate;
      }

      if (tempModel && tempModel.id !== prevVehicleState.id_model) {
        updates.id_model = tempModel.id;
        updates.name_model = tempModel.label;
      }

      if (tempManufacturer && tempManufacturer.id !== prevVehicleState.id_manufacturer && tempModel) {
        updates.id_manufacturer = tempManufacturer.id;
        updates.name_manufacturer = tempManufacturer.label;
      }

      if (tempItv && tempItv !== prevVehicleState.date_nextitv) {
        console.log(tempItv);
        updates.date_nextitv = tempItv;
      }

      return { ...prevVehicleState, ...updates };
    });

    // Salir del modo de edición
    setEditMode(false);
  };

  const formatKm = (km) => {
    if (km) return new Intl.NumberFormat("es-ES").format(km);
    else return "";
  };

  const handleKmChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    const formattedValue = new Intl.NumberFormat("es-ES").format(value);

    setTempKm(formattedValue);
  };

  return (
    <Stack sx={{ position: "relative", width: "320px", maxWidth: "320px", height: "100vh", backgroundColor: "secondary.light", pointerEvents: isConfirmed ? "none" : "auto" }}>
      {!isConfirmed && (
        <IconButton onClick={() => setEditMode(!editMode)} sx={{ position: "absolute", right: "15px", top: "15px", zIndex: "10" }}>
          {editMode ? <CloseIcon /> : <EditIcon />}
        </IconButton>
      )}
      {!editMode && (
        <motion.div initial={{ opacity: 0, transform: `translateX(-100px)` }} animate={{ opacity: 1, transform: "translateX(0)" }} transition={{ duration: 0.2 }}>
          <Stack padding={"15px"} gap={"20px"}>
            <Stack>
              <Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "flex-start", paddingRight: "40px", flexWrap: "wrap" }}>
                <Typography sx={{ textTransform: "UPPERCASE", fontWeight: "200", fontSize: "1.5rem", marginRight: "10px" }}>{vehicle?.name_manufacturer || "Sin especificar"}</Typography>
                <Typography sx={{ fontWeight: "200", fontSize: "1.5rem", color: "primary.main" }}>{vehicle?.name_model || ""}</Typography>
              </Stack>
            </Stack>
            <Stack onClick={() => setEditMode(!editMode)} sx={{ alignItems: "center", justifyContent: "center", gap: "10px", "&:hover": { cursor: "pointer" } }}>
              <Stack direction={"row"} sx={{ width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                <Stack direction={"column"} sx={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)", width: "calc(50% - 15px)", padding: "5px", alignItems: "center", justifyContent: "flex-start" }}>
                  <Typography sx={{ display: "flex", alignItems: "center", fontWeight: "300", fontSize: "0.8rem" }}>
                    <FingerprintIcon sx={{ marginRight: "5px", height: "15px", width: "15px" }} />
                    Matricula
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "1rem" }}>{vehicle?.plate || "Sin especificar"}</Typography>
                </Stack>
                <Stack direction={"column"} sx={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)", width: "calc(50% - 15px)", padding: "5px", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography sx={{ display: "flex", alignItems: "center", fontWeight: "300", fontSize: "0.8rem" }}>
                    <StraightenIcon sx={{ marginRight: "5px", height: "15px", width: "15px" }} />
                    Kilometraje
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "1rem" }}>{vehicle?.km ? `${formatKm(vehicle?.km)} kms` : "Sin especificar"} </Typography>
                </Stack>
              </Stack>
              <Stack direction={"row"} sx={{ width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                <Stack direction={"column"} sx={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)", width: "calc(50% - 15px)", padding: "5px", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography sx={{ display: "flex", alignItems: "center", fontWeight: "300", fontSize: "0.8rem" }}>
                    <CalendarMonthIcon sx={{ marginRight: "5px", height: "15px", width: "15px" }} />
                    Fecha interven...
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "1rem" }}>{new Date(intervention?.created_at).toLocaleDateString() || "Sin especificar"}</Typography>
                </Stack>
                <Stack direction={"column"} sx={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)", width: "calc(50% - 15px)", padding: "5px", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography sx={{ display: "flex", alignItems: "center", fontWeight: "300", fontSize: "0.8rem" }}>
                    <PersonIcon sx={{ marginRight: "5px", height: "15px", width: "15px" }} />
                    Cliente
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                  >
                    {userOwner?.name_first || userOwner?.name_last ? `${userOwner?.name_first || ""} ${userOwner?.name_last || ""}`.trim() : "Sin especificar"}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"column"} sx={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)", width: "calc(100% - 15px)", padding: "5px", alignItems: "center", justifyContent: "space-between" }}>
                <Typography sx={{ display: "flex", alignItems: "center", fontWeight: "300", fontSize: "0.8rem" }}>
                  <CalendarMonthIcon sx={{ marginRight: "5px", height: "15px", width: "15px" }} />
                  Próxima ITV
                </Typography>
                <Typography sx={{ fontWeight: "500", fontSize: "1rem" }}>
                  {vehicle?.date_nextitv ? new Date(vehicle.date_nextitv).toLocaleDateString() : vehicle?.date_nextitv ? new Date(vehicle.date_nextitv).toLocaleDateString() : "Sin especificar"}
                </Typography>
              </Stack>
            </Stack>

            <Stack>
              <TextField
                InputProps={{
                  style: {
                    fontSize: "0.8rem",
                    fontWeight: "400",
                    padding: "10px",
                    borderRadius: "0px",
                  },
                }}
                multiline
                rows={6}
                placeholder="Observaciones"
              />
            </Stack>
          </Stack>
        </motion.div>
      )}
      {editMode && (
        <motion.div initial={{ opacity: 0, transform: `translateX(-100px)` }} animate={{ opacity: 1, transform: "translateX(0)" }} transition={{ duration: 0.2 }}>
          <Stack sx={{ padding: "8px", paddingTop: "10px", gap: "10px" }}>
            <Typography sx={{ textTransform: "UPPERCASE", fontWeight: "200", fontSize: "1.5rem", marginRight: "10px", marginBottom: "5px", marginLeft: "10px" }}>EDITA LOS DATOS</Typography>
            <Autocomplete
              value={tempManufacturer}
              onChange={handleManufacturerChange}
              sx={{ "& fieldset": { borderRadius: "0" } }}
              options={allManufacturers}
              // required
              renderInput={(params) => <TextField {...params} label="Fabricante" variant="outlined" />}
            />
            {tempManufacturer && (
              <Autocomplete
                sx={{ "& fieldset": { borderRadius: "0" }, padding: "0" }}
                onChange={(e, newModel) => setTempModel(newModel)}
                options={allModels.filter((model) => model.manufacturer === tempManufacturer?.id)}
                value={tempModel}
                renderInput={(params) => <TextField {...params} label="Modelo" variant="outlined" />}
              />
            )}
            <Stack gap={"10px"} direction={"row"}>
              <TextField
                value={tempPlate}
                onChange={(e) => setTempPlate(e.target.value)}
                sx={{ width: "50%" }}
                InputProps={{
                  style: {
                    borderRadius: "0px",
                  },
                }}
                label="Matricula"
                // required
              />
              <TextField
                InputProps={{
                  style: {
                    borderRadius: "0px",
                  },
                  endAdornment: tempKm && <InputAdornment position="end">kms</InputAdornment>,
                }}
                label="Kilometraje"
                value={tempKm}
                sx={{ width: "50%" }}
                onChange={(e) => handleKmChange(e)}
                // required
              />
            </Stack>
            <Stack gap={"10px"} direction={"row"}>
              <TextField
                value={tempName}
                InputProps={{
                  style: {
                    borderRadius: "0px",
                  },
                }}
                label="Nombre"
                // required
                onChange={(e) => setTempName(e.target.value)}
              />
              <TextField
                value={tempSurname}
                InputProps={{
                  style: {
                    borderRadius: "0px",
                  },
                }}
                label="Apellido"
                // required
                onChange={(e) => setTempSurname(e.target.value)}
              />
            </Stack>
            <Stack gap={"10px"} direction={"row"}>
              <DatePicker
                value={tempDate}
                onChange={setTempDate}
                weekDays={weekDays}
                months={months}
                zIndex={3000}
                calendarPosition={"top"}
                format="D/M/YYYY"
                animations={[
                  opacity(),
                  transition({
                    from: 40,
                    transition: "all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)",
                  }),
                ]}
                render={<TextField inputProps={{ readOnly: true }} label="Fecha de intervención" sx={{ width: "100%" }} />}
                className="orange"
              />
              <DatePicker
                value={tempItv}
                onChange={setTempItv}
                weekDays={weekDays}
                months={months}
                zIndex={3000}
                calendarPosition={"top"}
                format="M/YYYY"
                onlyMonthPicker
                animations={[
                  opacity(),
                  transition({
                    from: 40,
                    transition: "all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)",
                  }),
                ]}
                render={<TextField inputProps={{ readOnly: true }} label="Próxima ITV" sx={{ width: "100%" }} />}
                className="orange derecha"
              />
            </Stack>
            <Button onClick={handleSave} disabled={isButtonDisabled()} sx={{ width: "100%", padding: "10px 30px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)" }} variant="contained">
              Guardar
            </Button>
          </Stack>
        </motion.div>
      )}
      <Stack
        height={"calc(100vh - 400px)"}
        marginBottom={"50px"}
        overflow={"auto"}
        sx={{
          width: "calc(100% - 30px)",
          padding: "15px",
          paddingTop: "0px",
          gap: "10px",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "#a8a8a8",
          },
        }}
      >
        {modifiedCarcomponents
          .sort((a, b) => b.status - a.status)
          .map((carcomponent) => (
            <SidebarCarcomponent carcomponent={carcomponent} />
          ))}
      </Stack>
      <img src={"/img/drivi_logo.svg"} style={{ width: "55px", position: "absolute", bottom: "7px", left: 0, right: 0, margin: "auto", padding: "0.5em", borderRadius: "5px" }} />
    </Stack>
  );
};

export default SidebarComponent;
