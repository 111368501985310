import { Stack, Typography, Container, AccordionDetails, AccordionSummary, Accordion, Alert } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LinearProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { motion } from 'framer-motion';
import ReactSpeedometer from "react-d3-speedometer"
import { Transition } from 'react-d3-speedometer'

const PieceComponent = ({ part }) => {



    const calculateStatus = (part) => {

      const statusCalculation = (part) => {
        const partChangeDate = new Date(part.created_at);
        const currentDate = new Date();
        const millisecondsInMonth = 30 * 24 * 60 * 60 * 1000; // Asumiendo un mes de 30 días
        const partExpiryDateInMilliseconds = part.caducidad_meses * millisecondsInMonth;

        const totalLifeTime = partExpiryDateInMilliseconds;
        const elapsedTime = currentDate.getTime() - partChangeDate.getTime();


        const remainingLifePercentage = Math.max(0, Math.min(100, (1 - (elapsedTime / totalLifeTime)) * 100));

        return remainingLifePercentage;
      }

      if (part.status === 0) {
        return statusCalculation(part);
      } else if (part.status === 1) {
        return statusCalculation(part)/3.5;
      } else if (part.status === 2) {
        return 0;
      }
    };

    const partRevisionCalculation = (part) => {

      const partChangeDate = new Date(part.created_at);
      const currentDate = new Date();
      const timeFromRevision = Math.trunc((currentDate.getTime() - partChangeDate.getTime()) / 1000 / 60 / 60 / 24 / 30);

      return timeFromRevision
    }

    const calculateStatusMonths = (part) => {
      const monthsCalculation = (part) => {
        const partChangeDate = new Date(part.created_at);
        const currentDate = new Date();
        const millisecondsInMonth = 30 * 24 * 60 * 60 * 1000;
        const partExpiryDateInMilliseconds = part.caducidad_meses * millisecondsInMonth;

        const totalLifeTime = partExpiryDateInMilliseconds;
        const elapsedTime = currentDate.getTime() - partChangeDate.getTime();    
        if (part.is_piece) {
        const remainingLifeTime = (partExpiryDateInMilliseconds - elapsedTime) / 1000 / 60 / 60 / 24;
        const remainingLifeTimeInMonths = Math.trunc(remainingLifeTime / 30);

        return remainingLifeTimeInMonths;
        } else {
            const timeFromRevision = Math.trunc(elapsedTime / 1000 / 60 / 60 / 24 / 30)
            return timeFromRevision
        }
      }
        
      if (part.status === 0) {
        return monthsCalculation(part);
      }

      if (part.status === 1 || part.status === 3) {
        return (monthsCalculation(part)/2);
      }

      if (part.status === 2) {
        return (monthsCalculation(part)/10);
      }

    };

    const renderStatus = (part) => {
        if (part.status === 0) {
            return "limegreen"
        } else if (part.status === 1 || part.status === 3) {
            return "yellow"
        } else if (part.status === 2) {
            return "#eb4242"
        }
    }

    const statusBarColor = (part) => {
        if (calculateStatus(part) > 50) {
            return "#59c259"
        } else if (calculateStatus(part) > 25) {
            return "yellow"
        } else if (calculateStatus(part) > 0) {
            return "#eb4242"
        }
    }

    const monthsColor = (part) => {
        if (calculateStatus(part) > 50) {
            return "black"
        } else if (calculateStatus(part) > 20) {
            return "#786a00"
        } else if (calculateStatus(part) > -1) {
            return "#a50101"
        }
    }

    const monthsBackgroundColor = (part) => {
      if (calculateStatus(part) > 50) {
          return "transparent"
      } else if (calculateStatus(part) > 20) {
          return "#fffccc"
      } else if (calculateStatus(part) > -1) {
          return "#ffcccc"
      }
  }
  const calculateMonthsSinceCaducity = (part) => {
    const partChangeDate = new Date(part.created_at);
    const currentDate = new Date();

    let caducidad
    
    if (part.status === 0) {
      caducidad = part.caducidad_meses;
    } else if (part.status === 1) {
      caducidad = part.caducidad_meses/3.5;
    } else if (part.status === 2) {
      caducidad = part.caducidad_meses/999;
    }


    
    // Calcular la fecha de caducidad de la pieza
    const millisecondsInMonth = 30 * 24 * 60 * 60 * 1000; // Asumiendo un mes de 30 días
    const partExpiryDateInMilliseconds = caducidad * millisecondsInMonth;
    

    const partExpiryDate = part.status === 2 ? partChangeDate : new Date(partChangeDate.getTime() + partExpiryDateInMilliseconds);
    
    // Calcular los meses transcurridos desde la caducidad
    const monthsSinceCaducity = Math.max(0, Math.floor((currentDate.getTime() - partExpiryDate.getTime()) / millisecondsInMonth));
    
    return monthsSinceCaducity;
  }

    const renderStatusText = (part) => {
      const statusTextCalculation = (part) => {
        if (part.is_piece) {
          if (calculateStatus(part) <= 0) {
            if (calculateMonthsSinceCaducity(part) == 0) {
              return `Caducó este mes`;
            } else if (calculateMonthsSinceCaducity(part) === 1) {
              return `Caducó hace un mes`;
            } else {
              return `Caducó hace ${calculateMonthsSinceCaducity(part)} meses`;
            }
           ;
          } else {
            return `Caduca en ${calculateStatusMonths(part)} meses`;
          }
        } else {
          const monthsResult = calculateStatusMonths(part);

          if (monthsResult > 1) {
            return `Se revisó hace ${monthsResult} meses`;
          } else if (monthsResult === 1) {
            return `Se revisó hace 1 mes`;
          } else {
            return `Se revisó recentemente`;
          }
        }
      };

         if (part.status === 0) {
        return statusTextCalculation(part);
      } else if (part.status === 1 || part.status === 3) {
        if (partRevisionCalculation(part) <= 1) {
          return `Regular`;
        } else {
          return `Regular`;
        }
      } else if (part.status === 2) {
        if (partRevisionCalculation(part) <= 1) {
          return `Deficiente`;
        } else {
          return `Deficiente`;
        }
      }
    };

    const statusTextCalculation = (part) => {
      if (part.is_piece) {
        if (calculateStatus(part) <= 0) {
            if (calculateMonthsSinceCaducity(part) === 0) {
              return `Caducó este mes`;
            } else if (calculateMonthsSinceCaducity(part) === 1) {
              return `Caducó hace un mes`;
            } else {
              return `Caducó hace ${calculateMonthsSinceCaducity(part)} meses`;
            }
        } else {
          return `Caduca en ${calculateStatusMonths(part)} meses`;
        }
      } else {
        const monthsResult = calculateStatusMonths(part);

        if (monthsResult > 1) {
          return `Se revisó hace ${monthsResult} meses`;
        } else if (monthsResult === 1) {
          return `Se revisó hace 1 mes`;
        } else {
          return `Se revisó recentemente`;
        }
      }
    };

    const renderStatusTextUncolored = (part) => {
    
      
      if (part.is_piece || part.status === 0 ) {
        return statusTextCalculation(part);
      } else if (part.status === 1 || part.status === 3) {
        if (partRevisionCalculation(part) <= 1) {
          return `Recientemente estaba`;
        } else {
          return `Hace ${partRevisionCalculation(part)} meses estaba`;
        }
      } else if (part.status === 2) {
        if (partRevisionCalculation(part) <= 1) {
          return `Recientemente estaba`;
        } else {
          return `Hace ${partRevisionCalculation(part)} meses estaba`;
        }
      }}
    
    

    function capitalize(text) {

        let textLowercase = text.toLowerCase();
        let result = textLowercase.charAt(0).toUpperCase() + textLowercase.slice(1);
        return result;
    }

    const determineCurrentValueText = (value, part) => {

      if (part.is_piece) {
        if (value > 50) {
          return "OK";
        } else if (value > 20) {
          return "Aceptable";
        } else {
          return "Reemplazar";
        }
      } else if (part.name === "Neumáticos Delanteros" || part.name === "Neumáticos Traseros" || part.name === "Pastillas de Freno Delanteras"|| part.name === "Pastillas de Freno Traseras" || part.name === "Frenos Traseros") {
        return  `${part.name.split(" ")[0]} al ${Math.round(calculateStatus(part)).toString()}%`;
      } else {
        if (value > 30) {
          return "OK";
        } else if (value > 10) {
          return "Revisar";
        } else if (value >= 0) {
          return "Revisar urgente";
        }
      }
    }

    const renderPartName = (part) => {
      if (part.name.includes("Alineamiento Neumáticos")) {
        return "Estado neumáticos";
      } else {
        return capitalize(part.name);
      }
    };

    return (
      <Stack sx={{ alignItems: "center", justifyContent: "center", backgroundColor: "#f5f5f5", gap: "5px", padding: "10px", minWidth: "250px", maxWidth: "250px" }}>
        <Typography sx={{ textAlign: "center", fontWeight: "300", textWrap: "nowrap", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{renderPartName(part)}</Typography>
        <Typography sx={{ fontWeight: "300" }}>
          <Stack direction={"row"} gap={"0px"} padding={"0px"} alignItems={"center"} justifyContent={"center"}>
            <Typography sx={{ fontWeight: "300", padding: "0px 10px", textAlign: "center", fontSize: "0.7rem" }}> {renderStatusTextUncolored(part)} </Typography>
            {!part.is_piece && part.status !== 0 && (
              <Typography sx={{ fontWeight: "300", padding: "0px 10px", textAlign: "center", fontSize: "0.7rem", backgroundColor: `${monthsBackgroundColor(part)}`, color: `${monthsColor(part)}` }}>
                {" "}
                {renderStatusText(part)}{" "}
              </Typography>
            )}
          </Stack>
        </Typography>
        <Stack sx={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
          <ReactSpeedometer
            customSegmentStops={part.is_piece ? [0, 20, 50, 100] : [0, 10, 30, 100]}
            paddingVertical={10}
            needleTransitionDuration={calculateStatus(part) * Math.random() * 5 + 10}
            textColor={part.is_piece ? (calculateStatus(part) < 20 ? "red" : "black") : calculateStatus(part) === 0 ? "red" : "black"}
            segmentColors={["#ff471a", "#efc421", "#92dd2a"]}
            height={"fit-content"}
            width={200}
            currentValueText={determineCurrentValueText(calculateStatus(part), part)}
            customSegmentLabels={["Bien", "Casi Vencida", "Vencida"]}
            needleColor={"black"}
            needleHeightRatio={0.7}
            ringWidth={40}
            needleTransition={Transition.easeBounceOut}
            minValue={0}
            maxValue={100}
            segments={3}
            value={calculateStatus(part)}
          />
        </Stack>
      </Stack>
    );
}

const HealthComponent = ({ vehicle, lastSection }) => {
    const [pieceExpandedState, setPieceExpandedState] = React.useState(true);
    const [revisionExpandedState, setRevisionExpandedState] = React.useState(true);

    const handleExpand = (panel) => (event, isExpanded) => {
        if (panel === "piece") {
            setPieceExpandedState(isExpanded);
        } else if (panel === "revision") {
            setRevisionExpandedState(isExpanded);
        }
    };

    const animationDirection = () => {
        if (lastSection === "info") {
            return "50px"
        } else if (lastSection === "intervention") {
            return "-50px"
        } else {
            return "0px"
        }
    }


    return (<>
        <motion.div initial={{ opacity: 0, transform: `translateX(${animationDirection()})` }} animate={{ opacity: 1, transform: "translateX(0)" }} transition={{ duration: 0.2 }}>
            <Stack gap={"10px"} sx={{ width: "calc(100%-30px)", padding: "15px", height: "fit-content", justifyContent: "center" }}>
                <Accordion style={{ maxWidth: "1000px", boxShadow: "0 0 10px 3px rgba(0, 0, 0, 0.1)" }} onChange={handleExpand("piece")} expanded={pieceExpandedState} >
                    <AccordionSummary style={{ maxWidth: "1000px", display: "flex", alignItems: "center" }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography width={"100%"} marginTop={"10px"} color={"grey"} sx={{ textTransform: "uppercase", fontWeight: "200", }}>Piezas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack alignItems={"center"} justifyContent={"space-evenly"} direction={"row"} gap={"20px"} flexWrap={"wrap"}>
                            {vehicle && vehicle.carcomponents.map((part) => part.is_piece && <PieceComponent part={part} />)}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ maxWidth: "1000px", boxShadow: "0 0 10px 3px rgba(0, 0, 0, 0.1)" }} onChange={handleExpand("revision")} expanded={revisionExpandedState}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >                <Typography width={"100%"} marginTop={"10px"} color={"grey"} sx={{ textTransform: "uppercase", fontWeight: "200", }}>Revisiones</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack alignItems={"center"} justifyContent={"space-evenly"} direction={"row"} marginTop={"10px"} gap={"20px"} flexWrap={"wrap"}>
                            {vehicle && vehicle.carcomponents.map((part) => !part.is_piece && <PieceComponent part={part} />)}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </Stack></motion.div >
    </>)
}

export default HealthComponent