import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom"
import { ChangePassword, recoveryPasswordUpdate } from '../../../services/API/Auth.Services';
import { logout } from '../../../utils/SessionFunctions';
import { getStorageObject, setStorageObject } from '../../../utils/LocalStorage';
import { useToast } from '../../../components/toastComponent/toast.Actions';

const ChangePasswordComponent = ({id, token}) => {

  const { openCustomToast } = useToast()

  const navigate = useNavigate()
  const { register, handleSubmit } = useForm()

  const [showPasswordState, setShowPasswordState] = useState(false);
  const [showConfirmPasswordState, setShowConfirmPasswordState] = useState(false);
  const [loadingState] = useState(false);
  const [disableButtonState] = useState(false);

  const [passwordState, setPasswordState] = useState("");
  const [confirmPasswordState, setConfirmPasswordState] = useState("");

  const handleClickShowPassword = () => {
    setShowPasswordState(!showPasswordState);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPasswordState(!showConfirmPasswordState);
  };

  const onSubmit = async (data) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\dáéíóúÁÉÍÓÚñÑ@$!%*?&]{6,}$/;

    if (!passwordRegex.test(passwordState)) {
      openCustomToast("La contraseña debe tener al menos 6 caracteres, una letra mayúscula, una letra minúscula y un número", "error", false);
    } else if (passwordRegex.test(passwordState)) {
      if (passwordState === confirmPasswordState && passwordState !== "" && confirmPasswordState !== "" && passwordRegex.test(passwordState)) {
        const _token = getStorageObject("token") || token;
        const _id = getStorageObject("userData")?.id || id;

        if (token) {
          recoveryPasswordUpdate(token, passwordState, id).then(({data: userData}) => {
            openCustomToast("Contraseña cambiada satisfactoriamente", "success", false);
            setStorageObject("userData", userData.user);
            setStorageObject("token", userData.token);
            setStorageObject("repairShopId", userData.user.id_user_repairshop);
            navigate("/clientes");
            window.location.reload();
          });
        } else {
          ChangePassword(_token, passwordState, _id).then(() => {
            let userData = getStorageObject("userData");
            if (userData) {
              userData.firstLogin = false;
              setStorageObject("userData", userData);

              openCustomToast("Contraseña cambiada, disfruta de Drivi", "success", false);
              window.location.reload();
            } else {
              logout();
            }
          });
        }
      } else {
        openCustomToast("Las contraseñas no coinciden", "error", false);
      }
    }
  };

  return (
    <Stack height={"100%"} justifyContent={"center"} alignItems={"center"}>
      <Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
        <img style={{ height: "35px" }} src="/img/drivi_logo.svg" alt="" />
        <Typography sx={{ marginTop: "10px", fontWeight: "200", color: "#e94e1b", fontSize: "1.5rem" }} fontWeight={"300"} variant="subtitle1">
          ¡Bienvenido!
        </Typography>
        <Typography fontWeight={"300"} variant="subtitle1">
          Cambia tu contraseña para usar Drivi
        </Typography>
      </Stack>
      <Stack gap={"20px"} sx={{ width: "fit-content", justifyContent: "center" }}>
        <form style={{ padding: "20px", width: "fit-content", display: "flex", flexDirection: "column", gap: "10px" }} onSubmit={handleSubmit(onSubmit)}>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Nueva contraseña</InputLabel>
            <Input
              {...register("password")}
              value={passwordState}
              onChange={(e) => setPasswordState(e.target.value)}
              id="standard-adornment-password"
              type={showPasswordState ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleClickShowPassword}>
                    {showPasswordState ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Confirmar nueva contraseña</InputLabel>
            <Input
              {...register("password-confirm")}
              value={confirmPasswordState}
              onChange={(e) => setConfirmPasswordState(e.target.value)}
              id="standard-adornment-password"
              type={showConfirmPasswordState ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleClickShowConfirmPassword}>
                    {showConfirmPasswordState ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Button disabled={disableButtonState} style={{ backgroundColor: disableButtonState ? "grey" : "primary.main", color: "white", marginTop: "30px", borderRadius: "0" }} width={"100%"} type="submit" variant="contained">
              Cambiar contraseña
            </Button>
          </FormControl>
          <Typography textAlign={"center"} color={"red"}>
            {loadingState && displayErrorMessage()}
          </Typography>
        </form>
      </Stack>
    </Stack>
  );
}

export default ChangePasswordComponent