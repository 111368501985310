import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";

const SidebarCarcomponent = ({ carcomponent }) => {
  const statusColors = [
    { key: 0, value: "#4abe48" },
    { key: 1, value: "#fd8d03" },
    { key: 2, value: "#e73d3d" },
  ];

  return (
    <motion.div initial={{ opacity: 0, translateX: "-30px" }} animate={{ opacity: 1, translateX: "0" }} transition={{ duration: 0.2 }}>
      <Stack
        direction={"row"}
        sx={{ width: "calc(100% - 20px)", maxWidth: "100%", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.15)", backgroundColor: "#e9e9e9", justifyContent: "space-between", padding: "5px 10px" }}
      >
        <Typography sx={{ fontWeight: "300", maxWidth: "85%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{carcomponent.name}</Typography>
        <Stack>
          <Stack
            sx={{
              transition: "all 0.4s",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "0.8rem",
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.15)",
              backgroundColor: carcomponent.isReplaced ? "#31872f" : statusColors[carcomponent.status].value,
            }}
          >
            {carcomponent.isReplaced ? (
              <motion.div initial={{ opacity: 0, translateX: "-30px" }} animate={{ opacity: 1, translateX: "0" }} transition={{ duration: 0.2 }}>
                R
              </motion.div>
            ) : null}

            {carcomponent.percentage && !carcomponent.isReplaced ? (
              <motion.div style={{ fontSize: "0.7rem" }} initial={{ opacity: 0, translateX: "-30px" }} animate={{ opacity: 1, translateX: "0" }} transition={{ duration: 0.2 }}>
                {carcomponent.percentage}
              </motion.div>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </motion.div>
  );
};

export default SidebarCarcomponent;
