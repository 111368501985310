import React, { useEffect, useRef, useState } from "react";
import SmsIcon from "@mui/icons-material/Sms";
import CloseIcon from "@mui/icons-material/Close";
import HeaderComponent from "../Header.Component";
import MainContent from "../MainContent.component";
import LoadingComponent from "../Loading.Component";

import { Button, IconButton, Link, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { getByPhoneMessages, postMessage } from "../../services/API/Whapi.Services";
import { getStorageObject } from "../../utils/LocalStorage";
import MessageComponent from "./components/Message.Component";
import ModalComponent from "../Modal.Component";
import useWebSocket, { ReadyState } from "react-use-websocket";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { CircularProgress } from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

import { useToast } from "../../components/toastComponent/toast.Actions";
import { generateResponse } from "../../services/API/OpenAI.Services";
import { demoAtom } from "../../context/atoms/Atoms.Storage";
import { useAtom } from "jotai";
import { motion } from "framer-motion";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useParams } from "react-router-dom";

const WhatsappModal = ({ onClose, userName, userPhone, userId, setUserId }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const whatsappWalkthrough = queryParams.get("whatsappWalkthrough");

  const toast = useToast();

  const scrollableDivRef = useRef(null);

  const server_url = process.env.REACT_APP_WEBSOCKET_URL;

  const WS_URL = server_url + "?userPhone=" + userPhone + "&userRepairshop=" + getStorageObject("repairShopId");

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(WS_URL);

  //STATES

  const [messageToSend, setMessageToSend] = useState();
  const [loadingState, setLoadingState] = useState(true);
  const [whapiState, setWhapiState] = useState();
  const [img, setImg] = useState();
  const [reloadChatState, setReloadChatState] = useState(false);
  const [messageGeneratorLoadingState, setMessageGeneratorLoadingState] = useState(false);
  const [demo, setDemo] = useAtom(demoAtom);

  //USEEFFECTS

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const data = lastJsonMessage;
      if (data) {
        setWhapiState((prevState) => [...prevState, data.messages[0]]);
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (demo) {
      setUserId("demo");
    }
  }, [demo]);

  const handleGenerateResponse = async () => {
    try {
      setMessageGeneratorLoadingState(true);

      const server_url = process.env.REACT_APP_SERVER_URL;
      const token = getStorageObject("token");
      const body = { userId: userId };

      const response = await fetch(`${server_url}/openai/generateresponse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let completeMessage = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });

        completeMessage += chunk;

        setMessageToSend(completeMessage);
      }

      if (!response.ok) {
        toast.openCustomToast("Error al generar la respuesta", "error", 3000);
        setMessageToSend("");
        setMessageGeneratorLoadingState(false);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
      toast.openCustomToast("Error al generar la respuesta", "error", 3000);
      setMessageToSend("");
    } finally {
      setMessageGeneratorLoadingState(false);
    }
  };

  const sendMessage = async () => {
    if (readyState === ReadyState.OPEN) {
      const id_user_repairshop = getStorageObject("repairShopId");
      sendJsonMessage({ type: "MESSAGE", id_user_repairshop: id_user_repairshop, message: messageToSend, phone: userPhone });
      setMessageToSend("");
    }
  };

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTo({
        top: scrollableDiv.scrollHeight,
      });
    }
  }, [whapiState]);

  useEffect(() => {
    const token = getStorageObject("token");

    getByPhoneMessages(token, userPhone)
      .then(({ data: messages, status, ok }) => {
        const editedMessages = messages?.messages?.filter((message) => {
          if (message.type === "action") {
            if (message.action.type === "edit") {
              return message.action;
            }
          }
        });

        const reactionMessages = messages?.messages?.filter((message) => {
          if (message.type === "action") {
            if (message.action.type === "reaction") {
              return message.action;
            }
          }
        });

        if (editedMessages) {
          editedMessages.forEach((editedMessage) => {
            const messageToEdit = messages?.messages?.find((message) => message.id === editedMessage.action.target);
            if (messageToEdit && messageToEdit.type === "link_preview") {
              messageToEdit.link_preview.body = editedMessage.action.edited_content.body;
            }
            if (messageToEdit) {
              messageToEdit.text.body = editedMessage.action.edited_content.body;
            } else {
              console.warn(`Message with id ${editedMessage.action.target} not found`);
            }
          });
        }

        if (reactionMessages) {
          reactionMessages.forEach((reactionMessage) => {
            const messageToEdit = messages?.messages?.find((message) => message.id === reactionMessage.action.target);
            if (messageToEdit) {
              messageToEdit.emoji = reactionMessage.action.emoji;
            } else {
              console.warn(`Message with id ${editedMessage.action.target} not found`);
            }
          });
        }

        setWhapiState(messages?.messages?.toReversed());
        setImg(messages?.icon);
        setLoadingState(false);
      })
      .catch((error) => {
        toast.openCustomToast("Error al cargar la conversación", "error", 3000), setLoadingState(false), onClose();
      });
  }, [reloadChatState]);

  //STYLES

  const handleError = () => {
    setImg("/img/defaultProfilePicture.svg");
  };

  //RENDER

  if (loadingState) {
    return <LoadingComponent />;
  }

  return (
    <ModalComponent>
      <Stack sx={{ position: "relative", width: "calc(100% - 50px)", backgroundColor: "white", height: "calc(100% - 50px)", boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.15)" }}>
        <Tooltip title={`Utiliza el botón "generar mensaje" para generar una respuesta con la magia de Drivi`} sx={{ zIndex: "999999" }} placement="top">
          <NewReleasesIcon sx={{ position: "absolute", height: "50px", width: "50px", color: "#e94e1b", top: "-25px", right: "-25px" }} />
        </Tooltip>
        <Stack sx={{ flexDirection: "row", justifyContent: "space-between", padding: "10px 15px", boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.15)" }}>
          <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
            <img style={{ height: "35px", width: "35px", borderRadius: "100%", boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)" }} src={img} onError={handleError} />
            <Typography sx={{ fontWeight: "200", fontSize: "1.5rem" }}>{userName}</Typography>
            <Button
              disabled={messageGeneratorLoadingState}
              onClick={handleGenerateResponse}
              variant="contained"
              sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px", marginLeft: "5px", width: "210px", boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0)" }}
            >
              {!messageGeneratorLoadingState ? (
                <>
                  Generar mensaje <AutoAwesomeIcon sx={{ height: "20px", width: "20px" }} />{" "}
                </>
              ) : (
                <CircularProgress size={"20px"} />
              )}
            </Button>
            {whatsappWalkthrough && (
              <motion.div initial={{ transform: "translateX(0px)" }} animate={{ transform: "translateX(10px)" }} transition={{ duration: 0.4, repeatType: "reverse", repeat: Infinity }}>
                <ArrowForwardIcon sx={{ rotate: "180deg", height: "25px", width: "25px", color: "primary.main" }} />
              </motion.div>
            )}
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          ref={scrollableDivRef}
          sx={{
            overflow: "auto",
            padding: "30px",
            height: "100%",
            gap: "20px",
          }}
        >
          {whapiState && whapiState?.length > 0 ? (
            whapiState?.map((message, index) => <MessageComponent message={message} key={index} />)
          ) : (
            <Stack sx={{ gap: "20px", width: "100%", minHeight: "100%!important", alignItems: "center", justifyContent: "center" }}>
              <img style={{ maxWidth: "200px" }} src="/img/drivi_logo.svg" alt="" />
              <Typography sx={{ fontSize: "2rem", fontWeight: "200", textAlign: "center" }}>Todavia no hay mensajes</Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction={"row"} sx={{ justifyContent: "center", width: "100%!important" }}>
          <TextField
            InputProps={{
              sx: {
                borderRadius: 0,
                "& fieldset": {
                  borderRadius: "0",
                },
              },
            }}
            sx={{
              width: "90%!important",
              borderRadius: 0,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: 0,
                },
                "&:hover fieldset": {
                  borderRadius: 0,
                },
                "&.Mui-focused fieldset": {
                  borderRadius: 0,
                },
              },
            }}
            onKeyDown={(e) => e.key === "Enter" && sendMessage()}
            value={messageToSend}
            onChange={(e) => setMessageToSend(e.target.value)}
            fullWidth
            multiline
            maxRows={8}
            width={"100%!important"}
          />
          <Button onKeyDown={(e) => e.key === "Enter" && sendMessage()} onClick={sendMessage} sx={{ width: "10%", height: "100%" }} variant="contained">
            Enviar
          </Button>
        </Stack>
      </Stack>
    </ModalComponent>
  );
};

export default WhatsappModal;
