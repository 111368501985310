import { Container, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useNavigate } from 'react-router-dom'
import { updateNotification } from '../../../services/API/Notification.Services';
import { getStorageObject } from '../../../utils/LocalStorage';

const NotificationComponent = ({route, setNotificationCenterIsOpenState, setNotificationsState, notifications, isRead, isPermanent, id, text, type, key}) => {

  const navigate = useNavigate();

  const deleteNotification = async () => {

    const token = getStorageObject("token");

    const notificationIndex = notifications.findIndex(notification => notification.id === id);
    if (notificationIndex !== -1) {
      const newNotifications = [...notifications];
      newNotifications.splice(notificationIndex, 1);
      setNotificationsState(newNotifications);

      try {
        await updateNotification(token, id, "is_closed");
      } catch (error) {
        console.error(error);
      }

      if (newNotifications.length === 0) {
        setNotificationCenterIsOpenState(false);
      }
    }
  };

  const markAsClicked = async () => {
    try {
      const token = getStorageObject("token");
      await updateNotification(token, id, "is_clicked");
    } catch (error) {
      console.error(error);
    }
  };

  const markAsRead = async () => {
    try {
      const token = getStorageObject("token");
      await updateNotification(token, id, "is_read");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack
      key={key}
      direction={"row"}
      sx={{ position: "relative", maxHeight: "calc(100vh-50px)", alignItems: "center", justifyContent: isPermanent ? "center" : "space-between", margin: "0 10px", padding: "10px", boxShadow: "0 0 15px 2px rgba(0, 0, 0, 0.15)" }}
    >
      {!isRead && (
        <Container
          sx={{ position: "absolute", top: "-4px", left: "-4px", backgroundColor: "red", height: "10px", width: "10px", paddingLeft: "0!important", paddingRight: "0!important", borderRadius: "100%" }}
        ></Container>
      )}{" "}
      <Stack gap={"10px"} direction={"row"} sx={{ alignItems: "center" }}>
      {type === 1 && <ReportProblemIcon style={{ color: "#f7d01e", fontSize: "1.5rem" }} />}
      <Typography
        onClick={() => {navigate(route); markAsClicked(); markAsRead()}}
        sx={{ fontWeight: type === 1 ? "600" : "300", fontSize: "0.9rem", "&:hover": { cursor: route ? "pointer" : "default", textDecoration: route ? "underline" : "none" } }}
      >
        {text}
      </Typography>

      </Stack>
{   !isPermanent &&   <IconButton onClick={deleteNotification} sx={{ padding: "2px", height: "fit-content", width: "fit-content", zIndex: "1000" }}>
        <CloseIcon />
      </IconButton>}
    </Stack>
  );
}

export default NotificationComponent