import React, { useEffect, useState } from "react";
import { Stack, Button, ToggleButton, Typography, Divider, IconButton } from "@mui/material";
import TextTransition, { presets } from "react-text-transition";
import { useAtom } from "jotai";
import { demoAtom } from "../../context/atoms/Atoms.Storage.jsx";
import calendarDemoData from "../../utils/demoData/calendarData.json";
import { getCalendarData } from "../../services/API/Calendar.Services.js";
import HeaderComponent from "../../components/Header.Component.jsx";
import LoadingComponent from "../../components/Loading.Component.jsx";
import MainContent from "../../components/MainContent.component.jsx";
import FadeInComponent from "../../components/fadeIn.Component.jsx";
import { getStorageObject } from "../../utils/LocalStorage.js";
import CustomDatePicker from "./Components/Datepicker.Component.jsx";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NoInterventionsComponent from "../../components/noInterventions/NoInterventions.Component.jsx";


function CalendarButton({ _month, setMonthState, month, calendarData, ModifyButtonIntensity }) {
  const isToggled = _month === month ? true : false;

  return (
    <ToggleButton
      selected={!isToggled}
      sx={{
        transition: "0.3s",
        backgroundColor: "primary.main",
        color: "white",
        fontSize: "1rem",
        textTransform: "none",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        width: "60px",
        textAlign: "center",
        borderRadius: "0",
        fontWeight: "300",
        boxShadow: "0 0px 10px 0px rgba(0, 0, 0, 0.25)",
        value: _month,
        "&:hover": {
          backgroundColor: "#b33127",
        },
        "&.Mui-selected": {
          backgroundColor: "#cdcdcd",
          filter: `brightness(${ModifyButtonIntensity(calendarData, calendarData[_month].billing.total) * 0.3 + 0.7})`,
        },
      }}
      onClick={() => setMonthState(_month)}
      variant="contained"
    >
      {new Date(2023, _month).toLocaleString("es-ES", { month: "long" }).toUpperCase().substr(0, 3)}
    </ToggleButton>
  );
}

function ProjectionPage(_props) {

  const GetCurrentMonth = () => {
    const date = new Date();
    return date.getMonth();
  };

  const GetCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };


  const [calendarDataState, setCalendarDataState] = useState();
  const [monthState, setMonthState] = useState(GetCurrentMonth());
  const [yearState, setYearState] = useState(GetCurrentYear()+1);
  const [appointedBillingState, setAppointedBillingState] = useState(1000);
  const [loadingState, setLoadingState] = useState(true);
  const [chartUrlState, setChartUrlState] = useState("");
  const [demo] = useAtom(demoAtom);
  const [opacityState, setOpacityState] = useState(0);

  const [responsiveState, setResponsiveState] = useState(false);
  const [responsiveSmallState, setResponsiveSmallState] = useState(false);

  useEffect(() => {
    if (demo === true) {
      setAppointedBillingState(49123);
      setCalendarDataState(calendarDemoData.calendarData);
      setChartUrlState(calendarDemoData.chartUrl);

      setTimeout(() => {
        setOpacityState(1);
        setLoadingState(false);
      }, 1700);

    } else if (demo === false) {
      const token = getStorageObject("token");
      const userId = getStorageObject("userData")?.id;

      getCalendarData(token, yearState, userId).then(({ data: resCalendarData, }) => {

        setCalendarDataState(resCalendarData.calendarData);
        setAppointedBillingState(resCalendarData.appointedBilling);
        setChartUrlState(resCalendarData.chartUrl);

        setTimeout(() => {
          setOpacityState(1);
          setLoadingState(false);

        }, 1700);

      }).catch(error => {});
    }
  }, [demo, yearState]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1168) {
        setResponsiveState(true);
      } else {
        setResponsiveState(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setResponsiveSmallState(true);
      } else {
        setResponsiveSmallState(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ModifyButtonIntensity = (calendarData, monthBilling) => {
    const monthlyIncome = [];

    calendarData.forEach((month) => {
      monthlyIncome.push(month.billing.total);
    });

    let max = Math.max(...monthlyIncome);

    const buttonIntensity = monthBilling / max;

    return buttonIntensity;
  };

  const navigate = (InterventionURL) => {
    window.open(InterventionURL, '_blank');;
  }

  if (loadingState) {
    return <LoadingComponent />;
  }

  return (
    <>
     
        <MainContent>
          <HeaderComponent text={"Proyección"} icon={<TrendingUpIcon style={{ color: "#e94e1b", height: "40px", width: "40px" }} />} />
 {calendarDataState ? (<>
          <Stack boxShadow={"0 0px 10px 0px rgba(0, 0, 0, 0.25)"} backgroundColor={"#f3f3f3"} sx={{ width: responsiveSmallState ? "100%" : "720px", height: "fit-content", transition: "0.2s", opacity: opacityState }}>
            <Stack
              width={"100%"}
              direction={`${responsiveSmallState ? "column" : "row"}`}
              marginBottom={`${responsiveSmallState ? "0px" : "0px"}`}
              marginTop={`${responsiveSmallState ? "0px" : "0px"}`}
              height={"fit-content"}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"#f1f1f1"}
            >
              <Stack backgroundColor={"#dedede"} style={{}} p={"20px"} sx={{ width: responsiveSmallState ? "calc(100% - 40px)" : "55%" }} height={"fit-content"} gap="20px">
                <Stack gap={"0"} direction={"row"}>
                  <input
                    style={{
                      backgroundColor: "white",
                      height: "50px",
                      minWidth: "calc(100% - 50px)",
                      fontSize: "1.5rem",
                      fontWeight: "300",
                      width: "100px",
                      textAlign: "center",
                      outline: "none",
                      border: "none",
                      fontFamily: "Inter, sans-serif",
                    }}
                    type="number"
                    value={yearState}
                    min={GetCurrentYear() - 2}
                    max={GetCurrentYear() + 25}
                    onChange={(e) => setYearState(parseInt(e.target.value))}
                  />
                  <Stack width={"50px"} backgroundColor={"white"}>
                    <button
                      onClick={() => setYearState(yearState + 1)}
                      style={{
                        outline: "none",
                        border: "none",
                        backgroundColor: "transparent",
                        borderLeft: "2px solid #dedede",
                        height: "50%",
                        width: "50px!important",
                        cursor: "pointer",
                        fontFamily: "Inter, sans-serif",
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "200",
                      }}
                    >
                      +
                    </button>
                    <button
                      onClick={() => setYearState(yearState - 1)}
                      style={{
                        outline: "none",
                        border: "none",
                        backgroundColor: "transparent",
                        borderLeft: "2px solid #dedede",
                        borderTop: "2px solid #dedede",
                        height: "50%",
                        width: "50px!important",
                        cursor: "pointer",
                        fontFamily: "Inter, sans-serif",
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "200",
                      }}
                    >
                      -
                    </button>
                  </Stack>
                </Stack>
                {calendarDataState && !responsiveSmallState ? (
                  <Stack gap="20px">
                    <Stack gap="20px" direction="row">
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={0} setMonthState={setMonthState} calendarData={calendarDataState} />
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={1} setMonthState={setMonthState} calendarData={calendarDataState} />
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={2} setMonthState={setMonthState} calendarData={calendarDataState} />
                    </Stack>
                    <Stack gap="20px" direction="row">
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={3} setMonthState={setMonthState} calendarData={calendarDataState} />
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={4} setMonthState={setMonthState} calendarData={calendarDataState} />
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={5} setMonthState={setMonthState} calendarData={calendarDataState} />
                    </Stack>
                    <Stack gap="20px" direction="row">
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={6} setMonthState={setMonthState} calendarData={calendarDataState} />
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={7} setMonthState={setMonthState} calendarData={calendarDataState} />
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={8} setMonthState={setMonthState} calendarData={calendarDataState} />
                    </Stack>
                    <Stack gap="20px" direction="row">
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={9} setMonthState={setMonthState} calendarData={calendarDataState} />
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={10} setMonthState={setMonthState} calendarData={calendarDataState} />
                      <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={11} setMonthState={setMonthState} calendarData={calendarDataState} />
                    </Stack>
                  </Stack>
                ):                   <Stack sx={{alignItems: "center"}} gap="20px">
                <Stack gap="20px" sx={{ width: "100%", justifyContent: "space-evenly"}} direction="row">
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={0} setMonthState={setMonthState} calendarData={calendarDataState} />
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={1} setMonthState={setMonthState} calendarData={calendarDataState} />
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={2} setMonthState={setMonthState} calendarData={calendarDataState} />
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={3} setMonthState={setMonthState} calendarData={calendarDataState} />
                  </Stack>
                  <Stack gap="20px" sx={{ width: "100%", justifyContent: "space-evenly"}} direction="row">
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={4} setMonthState={setMonthState} calendarData={calendarDataState} />
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={5} setMonthState={setMonthState} calendarData={calendarDataState} />
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={6} setMonthState={setMonthState} calendarData={calendarDataState} />
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={7} setMonthState={setMonthState} calendarData={calendarDataState} />
                  </Stack>
                  <Stack gap="20px" sx={{ width: "100%", justifyContent: "space-evenly"}} direction="row">
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={8} setMonthState={setMonthState} calendarData={calendarDataState} />
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={9} setMonthState={setMonthState} calendarData={calendarDataState} />
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={10} setMonthState={setMonthState} calendarData={calendarDataState} />
                  <CalendarButton ModifyButtonIntensity={ModifyButtonIntensity} month={monthState} _month={11} setMonthState={setMonthState} calendarData={calendarDataState} />
                </Stack>
              </Stack>}
              </Stack>
              <Stack color={"Black"} sx={{ height: "100%", width: "100%" }}>
                <Typography color={"white"} fontWeight={200} backgroundColor={"primary.main"} textAlign={"center"} p="10px" variant="h3">
                  <span style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "300", fontSize: "1rem" }}>Facturación programada: </span>
                  {appointedBillingState.toLocaleString()}€
                </Typography>
                <Stack direction={responsiveSmallState ? "column" : "row"} sx={{ width: "100%" }} height={"100%"} alignItems={"flex-start"} justifyContent={"space-between"}>
                  <Stack width={responsiveSmallState ? "calc(100% - 20px)" : "40%"} height={"calc(100% - 20px)"} p={"10px"} gap={"10px"} alignItems={"flex-start"}>
                    <Stack width={"100%"} direction={"row"} gap={"90px"} alignItems={"center"} justifyContent={"space-between"}>
                      <Typography sx={{ fontWeight: "300" }}>Total</Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {" "}
                        <TextTransition springConfig={presets.stiff} translateValue="18px">
                          {calendarDataState[monthState]?.billing?.total.toLocaleString("en-US").replace(",", ".")}€
                        </TextTransition>
                      </Typography>
                    </Stack>
                    <Divider sx={{ width: "100%", backgroundColor: "black" }} />
                    <Stack width={"100%"} direction={"row"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>
                      <Typography sx={{ fontWeight: "300" }}> Filtros </Typography>
                      <Typography sx={{}}>
                        <TextTransition springConfig={presets.stiff} translateValue="18px">
                          {calendarDataState[monthState]?.billing?.filtros?.toLocaleString("en-US").replace(",", ".")}€
                        </TextTransition>
                      </Typography>
                    </Stack>
                    <Stack width={"100%"} direction={"row"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>
                      <Typography sx={{ fontWeight: "300" }}>Neumaticos </Typography>
                      <Typography sx={{}}>
                        <TextTransition springConfig={presets.stiff} translateValue="18px">
                          {" "}
                          {calendarDataState[monthState]?.billing?.neumaticos.toLocaleString("en-US").replace(",", ".")}€{" "}
                        </TextTransition>
                      </Typography>
                    </Stack>
                    <Stack width={"100%"} direction={"row"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>
                      <Typography sx={{ fontWeight: "300" }}>Lubricantes </Typography>
                      <Typography sx={{}}>
                        <TextTransition springConfig={presets.stiff} translateValue="18px">
                          {calendarDataState[monthState]?.billing?.lubricantes.toLocaleString("en-US").replace(",", ".")}€
                        </TextTransition>
                      </Typography>
                    </Stack>
                    <Stack width={"100%"} direction={"row"} gap={"10px"} alignItems={"center"} justifyContent={"space-between"}>
                      <Typography sx={{ fontWeight: "300" }}>Correas </Typography>
                      <Typography sx={{}}>
                        <TextTransition springConfig={presets.stiff} translateValue="18px">
                          {calendarDataState[monthState]?.billing?.correas.toLocaleString("en-US").replace(",", ".")}€
                        </TextTransition>
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    padding={responsiveSmallState ? "0" : "5px 0"}
                    backgroundColor={"white"}
                    width={"65%"}
                    sx={{ width: "100%" }}
                    height={"calc(100% - 10px)"}
                    overflow={"auto"}
                    boxShadow={"inset 0 -25px 30px -30px rgba(0, 0, 0, 0.15)"}
                    alignItems={"space-between"}
                    justifyContent={"flex-start"}
                  >
                    {calendarDataState && calendarDataState[monthState].billing.vehicles.length > 0 ? (
                      calendarDataState[monthState].billing.vehicles.map((_vehicle, index) => {
                        return (
                          <FadeInComponent index={index}>
                            <Button
                              margin={"0"}
                              variant="contained"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                backgroundColor: "white",
                                color: "black",
                                borderRadius: 0,
                                width: "100%",
                                height: "25px",
                                boxShadow: "none",
                                padding: responsiveSmallState ? "20px" : "0",
                                transition: "0.2s",
                                borderBottom: "1px solid black",
                                "&:hover": { paddingLeft: responsiveSmallState ? "22px" : "2px", boxShadow: "inset 0 0px 10px 0px rgba(0, 0, 0, 0.25)" },
                                backgroundColor: "white!important",
                                color: "black",
                              }}
                              onClick={() => navigate(`/vehiculo/${_vehicle.vehicle.id}`)}
                              key={index}
                            >
                              <span style={{ maxWidth: "calc(100% - 70px)", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {demo !== true ?
                                  `${_vehicle.vehicle.vehicle_manufacturer?.name || ""} ${_vehicle.vehicle.vehicle_model.name?.split(" ")[0]}${" "}`
                                  :
                                  `${_vehicle.vehicle.manufacturer_name} ${_vehicle.vehicle.model_name?.split(" ")[0]}${" "}`}
                              </span>{" "}
                              <span style={{ backgroundColor: "#8f8f8f", color: "white", padding: "0 5px", height: "22px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {" "}
                                {_vehicle.vehicle.plate}{" "}
                              </span>
                            </Button>
                          </FadeInComponent>
                        );
                      })
                    ) : (
                      <Typography p="10px" minHeight={"calc(100% - 20px)"} width={"calc(100% - 20px)"}>
                        No hay vehiculos agendados
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction={"row"} sx={{ width: '100%', height: '380px' }} backgroundColor={"white"} paddingTop={"20px"} maxWidth={"100%"} alignItems={"center"} justifyContent={"center"}>
              <IconButton sx={{ zIndex: 3000 }} onClick={() => setYearState(yearState - 1)}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <img src={chartUrlState} alt="" style={{ zIndex: 1,     width: responsiveSmallState ? 'calc(100% - 80px)' : '630px', 
    height: responsiveSmallState ? 'auto' : '380px',  backgroundColor: 'white' }} />
              <IconButton sx={{ zIndex: 3000 }} onClick={() => setYearState(yearState + 1)}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Stack>

          </Stack>
          <Stack marginBottom={"30px"} width={"100%"} marginTop={"30px"} alignItems={"center"} justifyContent={"center"} gap={"20px"} direction={"row"}>
            <Stack width={"720px"} alignItems={"center"} justifyContent={"center"}>
              <Typography
                backgroundColor={"primary.main"}
                p="10px"
                color={"white"}
                variant="h5"
                style={{ width: "calc(100% - 20px)", textAlign: "center" }}
                sx={{ fontWeight: "200", textAlign: "space-evenly" }}>
                PERIODOS DE REFUERZO DE MARKETING
              </Typography>
              <CustomDatePicker/>
            </Stack>
          </Stack>
      </>  
      ) : (
        <NoInterventionsComponent />
      )}</MainContent>
    </>
  );
}

export default ProjectionPage;
