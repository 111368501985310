import { CircularProgress, Stack, Typography } from "@mui/material";
import { MotionConfig } from "framer-motion";
import React from "react";
import { motion } from "framer-motion";

const LoadingComponent = () => {
  const logoStyle = {
    width: "200px",
    animation: "swing3D 4s infinite ease-in-out",
    transformStyle: "preserve-3d",
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      <Stack alignItems={"center"} justifyContent={"center"} gap={"20px"} height={"100vh"}>
        <Stack alignItems={"center"} justifyContent={"center"} gap={"20px"} height={"fit-content"} style={logoStyle}>
          <style>
            {`
          @keyframes swing3D {
            0% { transform: perspective(600px) rotateX(0deg) rotateY(0deg); }
            25% { transform: perspective(600px) rotateX(10deg) rotateY(10deg); }
            50% { transform: perspective(600px) rotateX(0deg) rotateY(0deg); }
            75% { transform: perspective(600px) rotateX(-10deg) rotateY(-10deg); }
            100% { transform: perspective(600px) rotateX(0deg) rotateY(0deg); }
          }
        `}
          </style>
          <img src="/img/drivi_logo.svg" alt="logo" />

          <Typography variant="h4" sx={{ fontWeight: "200" }}>
            CARGANGO LA <span style={{ color: "#e94e1b" }}> INTERVENCIÓN </span>
          </Typography>
          <CircularProgress />
        </Stack>
      </Stack>
    </motion.div>
  );
};

export default LoadingComponent;
