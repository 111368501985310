import { Button, Checkbox, FormControlLabel, IconButton, Radio, RadioGroup, Slider, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState, memo } from "react";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";

const CarcomponentComponent = memo(
  ({ isConfirmed, carcomponent, handleComponentChange, modifiedCarcomponents, isFirstIntervention }) => {
    const modifiedCarcomponent = modifiedCarcomponents.find((item) => item.id === carcomponent.id) || {};

    const initialStatus = modifiedCarcomponent.status !== undefined ? modifiedCarcomponent.status : 3;
    const initialIsReplaced = modifiedCarcomponent.isReplaced !== undefined ? modifiedCarcomponent.isReplaced : false;
    const initialCaducidadKm = modifiedCarcomponent.caducidadKm || carcomponent.caducidadKm;
    const initialCaducidadMeses = modifiedCarcomponent.caducidadMeses || carcomponent.caducidadMeses;
    const initialSliderValue = modifiedCarcomponent.percentage !== undefined ? modifiedCarcomponent.percentage : 100;
    const initialType = modifiedCarcomponent.type !== undefined ? modifiedCarcomponent.type : "";

    const [carcomponentStatus, setCarcomponentStatus] = useState(initialStatus);
    const [isReplaced, setIsReplaced] = useState(initialIsReplaced);
    const [caducidadKm, setCaducidadKm] = useState(initialCaducidadKm);
    const [caducidadMeses, setCaducidadMeses] = useState(initialCaducidadMeses);
    const [isPercentage, setIsPercentage] = useState(carcomponent.name.includes("Neumáticos") || carcomponent.name.includes("Pastillas"));
    const [sliderValue, setSliderValue] = useState(initialSliderValue);

    const [carcomponentType, setCarcomponentType] = useState(initialType);

    const [configurationOpen, setConfigurationOpen] = useState(false);

    const [matchRearToFrontTires, setmatchRearToFrontTires] = useState(carcomponent.id === 3 ? true : null);

    // useEffect(() => {
    //   if (carcomponent.id === 3 && matchRearToFrontTires) {
    //     const frontTires = modifiedCarcomponents.find((item) => item.id === 2);
    //     if (frontTires && frontTires.type) {
    //       setCarcomponentType(frontTires.type);
    //     }
    //   }
    // }, [configurationOpen, matchRearToFrontTires, modifiedCarcomponents]);

    const radio_colors_style = [
      { key: 0, value: "#4abe48" },
      { key: 1, value: "#fd8d03" },
      { key: 2, value: "#e73d3d" },
      { key: 3, value: "#bdbdbd" },
    ];

    const sliderStyles = {
      transition: "all 0.2s",
      color: carcomponentStatus === 3 ? "#bdbdbd" : sliderValue > 61 ? "#4abe48" : sliderValue > 29 ? "#fd8d03" : "#e73d3d",
    };

    const radioStyle = (_value, _isSelected) => {
      return {
        display: "flex",
        transition: "all 0.2s",
        flexWrap: "wrap",
        justifyContent: "center",
        background: "red",
        width: "55px",
        margin: "0!important",
        padding: "3px 6px",
        background: _isSelected && _value < 3 ? radio_colors_style[_value]?.value : "",
        color: _isSelected && _value < 3 ? "#ffffff" : "#333",
        "& .Mui-checked": {
          color: _isSelected && _value < 3 ? "#ffffff!important" : "#333",
        },
        "& span": {
          fontSize: "0.75rem",
        },
      };
    };

    const getAriaValueText = (value) => {
      return `${value}%`;
    };

    const handleStatusChange = (event) => {
      setCarcomponentStatus(parseInt(event.target.value));
      handleComponentChange({ ...carcomponent, status: parseInt(event.target.value) });
    };

    const handleType = (event) => {
      setCarcomponentType(event.target.value);
      handleComponentChange({ ...carcomponent, type: event.target.value });
    };

    const handleIsReplaced = () => {
      setIsReplaced(!isReplaced);
      if (isPercentage) {
        if (!isReplaced) {
          handleComponentChange({ ...carcomponent, status: 0, isReplaced: !isReplaced, percentage: 100 });
        } else {
          handleComponentChange({ ...carcomponent, status: carcomponentStatus, isReplaced: false, percentage: sliderValue });
        }
      } else {
        setCarcomponentStatus(0);
        handleComponentChange({ ...carcomponent, status: 0, isReplaced: !isReplaced });
      }
    };

    const handleSliderValue = (event, newValue) => {
      setSliderValue(newValue);

      if (newValue > 61) {
        setCarcomponentStatus(0);
        handleComponentChange({ ...carcomponent, status: 0, percentage: newValue });
      } else if (newValue > 29) {
        setCarcomponentStatus(1);
        handleComponentChange({ ...carcomponent, status: 1, percentage: newValue });
      } else {
        setCarcomponentStatus(2);
        handleComponentChange({ ...carcomponent, status: 2, percentage: newValue });
      }
    };

    return (
      <Stack
        sx={{
          transition: "0.2s",
          border: isReplaced ? "3px solid #4abe48" : "3px solid transparent",
          marginBottom: "20px",
          position: "relative",
          width: "270px",
          padding: "5px",
          paddingTop: "15px",
          gap: "10px",
          paddingBottom: "40px",
          boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.25)",
          marginTop: "20px",
          height: "90px",
          backgroundColor: "white",
          pointerEvents: isConfirmed ? "none" : "auto",
        }}
      >
        {isReplaced && (
          <Stack
            sx={{ position: "absolute", top: "-12px", left: "0", right: "0", margin: "auto", width: "fit-content" }}
            backgroundColor={"#4abe48"}
            padding={"3px 10px"}
            borderRadius={"50px"}
            color={"white"}
            textAlign={"center"}
            fontSize={"0.9em"}
            marginBottom={"10px"}
            alignItems={"center"}
          >
            Reemplazado
          </Stack>
        )}
        <Typography sx={{ fontWeight: "300", fontSize: "1rem", textAlign: "center" }}>{carcomponent.name}</Typography>

        {/* {(carcomponent.id === 1 || carcomponent.id === 2 || carcomponent.id === 3) && (
          <IconButton onClick={() => setConfigurationOpen(!configurationOpen)} sx={{ transition: "all 0.2s", position: "absolute", top: "5px", right: "5px", color: "#333", "&:hover": { color: "#4abe48" } }}>
            {configurationOpen ? <CloseIcon /> : <SettingsIcon />}
          </IconButton>
        )} */}

        {configurationOpen && carcomponent.id === 1 && (
          <motion.div initial={{ translateY: "50px", opacity: 0 }} animate={{ translateY: "0px", opacity: 1 }} transition={{ duration: 0.2 }}>
            <Stack height={"100%"} gap={"10px"} sx={{ width: "100%", padding: "10px" }}>
              <TextField
                onChange={(e) => handleType(e)}
                value={carcomponentType}
                InputProps={{
                  style: {
                    borderRadius: "0px",
                  },
                }}
                sx={{
                  width: "calc(100% - 20px)",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4abe48",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#4abe48",
                  },
                }}
                label="Tipo de aceite"
              />
            </Stack>
          </motion.div>
        )}

        {configurationOpen && carcomponent.id === 2 && (
          <motion.div initial={{ translateY: "50px", opacity: 0 }} animate={{ translateY: "0px", opacity: 1 }} transition={{ duration: 0.2 }}>
            <Stack height={"100%"} gap={"10px"} sx={{ width: "100%", padding: "10px" }}>
              <TextField
                onChange={(e) => handleType(e)}
                value={carcomponentType}
                InputProps={{
                  style: {
                    borderRadius: "0px",
                  },
                }}
                sx={{
                  width: "calc(100% - 20px)",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4abe48",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#4abe48",
                  },
                }}
                label="Tipo de neumático"
              />
            </Stack>
          </motion.div>
        )}
        {configurationOpen && carcomponent.id === 3 && (
          <motion.div initial={{ translateY: "50px", opacity: 0 }} animate={{ translateY: "0px", opacity: 1 }} transition={{ duration: 0.2 }}>
            <Stack height={"100%"} gap={"10px"} sx={{ width: "100%", padding: "10px" }}>
              <Stack gap={"10px"} direction={"row"}>
                <Tooltip title="Neumaticos delanteros y traseros iguales">
                  <Checkbox checked={matchRearToFrontTires} onChange={(e) => setmatchRearToFrontTires(e.target.checked)} />
                </Tooltip>

                <TextField
                  disabled={matchRearToFrontTires}
                  onChange={(e) => handleType(e)}
                  value={carcomponentType}
                  InputProps={{
                    style: {
                      borderRadius: "0px",
                    },
                  }}
                  sx={{
                    width: "calc(100% - 80px)",
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#4abe48",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#4abe48",
                    },
                  }}
                  label="Tipo de neumático"
                />
              </Stack>
            </Stack>
          </motion.div>
        )}

        {!isReplaced && !isPercentage && !configurationOpen && (
          <RadioGroup onChange={(e) => handleStatusChange(e)} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} value={carcomponentStatus} defaultValue="0">
            <FormControlLabel sx={radioStyle(0, carcomponentStatus === 0)} labelPlacement="bottom" value={0} control={<Radio />} label={"Óptimo"} />
            <FormControlLabel sx={radioStyle(1, carcomponentStatus === 1)} labelPlacement="bottom" value={1} control={<Radio />} label={"Regular"} />
            <FormControlLabel sx={radioStyle(2, carcomponentStatus === 2)} labelPlacement="bottom" value={2} control={<Radio />} label={"Deficiente"} />
            <FormControlLabel sx={radioStyle(3, carcomponentStatus === 3)} labelPlacement="bottom" value={3} control={<Radio />} label={"No rev"} />
          </RadioGroup>
        )}
        {isPercentage && !isReplaced && !configurationOpen && (
          <Stack direction={"row"} gap={"20px"} sx={{ padding: "10px", alignItems: "center" }}>
            <Typography
              sx={{
                width: "20%",
                textAlign: "center",
                transition: "all 0.2s",
                minWidth: "45px",
                backgroundColor: carcomponentStatus === 3 ? "#bdbdbd" : sliderValue > 61 ? "#4abe48" : sliderValue > 29 ? "#fd8d03" : "#e73d3d",
                padding: "5px 10px",
                borderRadius: "50px",
                color: "white",
              }}
            >
              <strong>{carcomponentStatus === 3 ? "?" : `${sliderValue}%`}</strong>
            </Typography>
            <Slider
              sx={sliderStyles}
              onChange={handleSliderValue}
              value={sliderValue}
              aria-label="Percentage"
              color="#4abe48"
              defaultValue={100}
              getAriaValueText={getAriaValueText}
              valueLabelDisplay="auto"
              shiftStep={30}
              step={10}
              marks
              min={0}
              max={100}
              width={"100%"}
            />

            <RadioGroup
              onChange={(e) => handleStatusChange(e)}
              sx={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
              value={carcomponentStatus}
              defaultValue="0"
            >
              <FormControlLabel sx={radioStyle(3, carcomponentStatus === 3)} labelPlacement="bottom" value={3} control={<Radio />} label={"No rev"} />
            </RadioGroup>
          </Stack>
        )}
        {isReplaced && !configurationOpen && (
          <Stack direction={"row"} gap={"10px"} padding={"10px"}>
            <TextField
              InputProps={{
                style: {
                  borderRadius: "0px",
                },
              }}
              value={caducidadKm}
              onChange={(e) => setCaducidadKm(e.target.value)}
              label="Caducidad Km"
              type="number"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp") {
                  setCaducidadKm((prev) => prev + (e.shiftKey ? 1000 : 100));
                  e.preventDefault();
                } else if (e.key === "ArrowDown") {
                  setCaducidadKm((prev) => Math.max(0, prev - (e.shiftKey ? 1000 : 100)));
                  e.preventDefault();
                }
              }}
              sx={{
                width: "50%",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4abe48",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#4abe48",
                },
              }}
            />
            <TextField
              InputProps={{
                style: {
                  borderRadius: "0px",
                },
              }}
              value={caducidadMeses}
              onChange={(e) => setCaducidadMeses(e.target.value)}
              label="Caducidad Meses"
              type="number"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp") {
                  setCaducidadMeses((prev) => prev + (e.shiftKey ? 10 : 1));
                  e.preventDefault();
                } else if (e.key === "ArrowDown") {
                  setCaducidadMeses((prev) => Math.max(0, prev - (e.shiftKey ? 10 : 1)));
                  e.preventDefault();
                }
              }}
              sx={{
                width: "50%",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4abe48",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#4abe48",
                },
              }}
            />
          </Stack>
        )}
        <IconButton onClick={handleIsReplaced} sx={{ width: "fit-content", position: "absolute", left: "0", right: "0", bottom: "-30px", margin: "auto", borderRadius: "0" }}>
          <ChangeCircleIcon
            sx={{
              transition: "all 0.5s",
              color: isReplaced ? "#4abe48" : "secondary.main",
              rotate: isReplaced ? "360deg" : "0",
              height: "40px",
              width: "40px",
              backgroundColor: "white",
              borderRadius: "100%",
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
            }}
          />
        </IconButton>
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    // Devuelve true si las props no han cambiado y deseas evitar el re-renderizado
    return prevProps.carcomponent === nextProps.carcomponent;
  }
);

export default CarcomponentComponent;
