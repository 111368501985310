import { setStorageObject } from './LocalStorage';
import { APILogin } from '../services/API/Auth.Services';

export const login = async (email, password, setUser, setFirstLogin, navigate) => {

    try {

        const { data, ok, status } = await APILogin(email, password);

        if (data.token) {
            setStorageObject("token", data.token)
            setStorageObject("repairShopId", data.user.id_user_repairshop)
            setStorageObject("userData", data.user)

            if (data.user.firstLogin) {
                setFirstLogin(true)
            } else {
                if (data.user.id_user_profile === 1) {
                    navigate("/admin");
                } else { navigate("/clientes"); }
                location.reload();
            }

        } else {
            console.error(data)
        }

        return ({ data, ok, status })
    }
    catch (error) {
        console.error('error', error)
        if (error) { return error }
        else { return "error" }
    }
};

export const logout = () => {

    localStorage.clear()
    window.location.reload()
}