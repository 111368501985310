import React, { useEffect, useState } from "react";
import MainContent from "../../components/MainContent.component";
import { Avatar, Typography, Link, Stack, Divider, Box, IconButton, Button, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import HeaderComponent from "../../components/Header.Component";
import WhatsappModal from "../../components/chatModalComponent/Whatsapp.Modal";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ModalComponent from "../../components/Modal.Component";
import DataBaseUpload from "./Components/DatabaseUpload.Component";
import InfoIcon from "@mui/icons-material/Info";

const SupportPage = () => {
  const [responsiveState, setResponsiveState] = useState(false);
  const [phoneModalIsOpen, setPhoneModalIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1168) {
        setResponsiveState(true);
      } else {
        setResponsiveState(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MainContent>
      {phoneModalIsOpen && (
        <ModalComponent onClick={() => setPhoneModalIsOpen(false)}>
          <Stack onClick={() => setPhoneModalIsOpen(false)} sx={{ position: "relative" }} backgroundColor={"white"} padding={"20px"} paddingTop={"30px"}>
            <Typography sx={{ fontSize: "1.8rem", fontWeight: "250" }}>684 74 75 53</Typography>
          </Stack>
        </ModalComponent>
      )}
      <Stack>
        <Stack height={"100%"} alignItems={"center"} justifyContent={"center"}>
          <HeaderComponent text={"¡Hola!"} icon={null} />
          <Avatar size="large" src="/img/hugo_atencio_cliente.jpeg" sx={{ width: 250, height: 250, margin: "20px" }} />
          <Typography fontWeight={300} width={"100%"} maxWidth={"500px"} variant="subtitle1">
            {"Soy Hugo, tu asistente personal. Para cualquier duda, puedes utilizar cualquiera de los siguientes canales:"}
          </Typography>
        </Stack>
        <Stack sx={{ alignItems: "center", justifyContent: "center", marginTop: "20px" }} direction={"row"} gap={"10px"}>
          <Button
            onClick={() => window.open("https://api.whatsapp.com/send/?phone=34684747553&text=Hola%21+necesito+ayuda&type=phone_number&app_absent=0", "_blank")}
            sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px" }}
          >
            <img src="/img/ico_whatsapp_orange.svg" alt="" width={"20px"} />
            WhatsApp
          </Button>
          <Button onClick={() => window.open("mailto:hprado@drivimobility.com", "_blank")} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px" }}>
            <EmailIcon />
            Email
          </Button>
          <Button onClick={() => setPhoneModalIsOpen(true)} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "5px" }}>
            <PhoneIcon />
            Teléfono
          </Button>
        </Stack>
        <Stack>
          <iframe
            style={{ marginTop: "20px", marginBottom: "-20px" }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/videoseries?si=MNnb7gaw1K6N_Tw5&amp;list=PLJHO0lO7w1tOu7u80e8z9i7jwpnRmU6x_"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Stack>
        <Stack margin={"20px"} marginTop={"60px"} justifyContent={"center"} gap={"20px"} alignItems={"center"} padding={"20px"} sx={{ boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.25)" }}>
          <Typography variant="h4" color={"primary.main"} fontWeight={"200"}>
            Descargar Client Profiler
          </Typography>
          <Stack mb={"30px"} direction={"row"} alignItems={"center"} justifyContent={"center"} gap={"10px"} sx={{ marginTop: "10px", maxWidth: "400px" }}>
            <Typography fontWeight={300} width={"100%"} maxWidth={"500px"} variant="subtitle1">
              Descarga e instala este programa, inicia sesión y selecciona la carpeta donde guardas tus facturas. Así podrás crear nuevos clientes.
            </Typography>
            <Box width={"40%"}>
              <IconButton
                sx={{ backgroundColor: "primary.main", color: "white" }}
                maxWidth={"50px!important"}
                onClick={() => window.open("https://taller.drivimobility.com/desktopinstaller/prod/DriviClientProfiler.msi", "_blank")}
              >
                <DownloadIcon sx={{ height: "50px", width: "50px" }} />
              </IconButton>
            </Box>
          </Stack>
        </Stack>
        <Stack direction={"row"} sx={{ gap: "10px", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
          <DataBaseUpload />
          <Tooltip arrow title="Para importar tu base de datos actual, primero debes exportarla desde tu aplicación. Luego, sube aquí el archivo exportado.">
            <InfoIcon sx={{ color: "primary.main" }} />
          </Tooltip>
        </Stack>
      </Stack>
    </MainContent>
  );
};

export default SupportPage;
