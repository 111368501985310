import { Button, CircularProgress, FormControl, IconButton, Input, InputAdornment, InputLabel, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { firstLoginAtom, userAtom } from "../../context/atoms/Atoms.Storage.jsx";
import { login } from "../../utils/SessionFunctions.js";
import { useToast } from "../../components/toastComponent/toast.Actions.jsx";
import ChangePasswordComponent from "../leads/Components/ChangePassword.Component.jsx";
import RecoverAccountComponent from "./components/RecoverAccount.Component.jsx";

const LoginPage = () => {
  //HOOKS

  const { openCustomToast } = useToast();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  //STATES

  const [showPasswordState, setShowPasswordState] = useState(false);
  const [userState, setUserState] = useAtom(userAtom);
  const [loadingState, setLoadingState] = useState(false);
  const [firstPasswordComponentState, setFirstPasswordComponentState] = useState(false);
  const [firstLogin, setFirstLogin] = useAtom(firstLoginAtom);
  const [recoverAccountState, setRecoverAccountState] = useState(false);

  //FUNCTIONS

  const handleClickShowPassword = () => {
    setShowPasswordState(!showPasswordState);
  };

  const onSubmit = async (data) => {
    try {
      setLoadingState(true);
      const response = await login(data.email, data.password, setUserState, setFirstLogin, navigate);

      if (response?.status == 200) {
        openCustomToast("Sesión iniciada", "success", false);
      } else if (response?.status == 211) {
        openCustomToast("Credenciales incorrectas", "error", false);
      } else {
        openCustomToast("Estamos teniendo problemas técnicos, refresca y vuelve a intentarlo", "error", false);
      }
      setLoadingState(false);
      navigate("/clientes");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack sx={{ height: "calc(100vh - 30px)", width: "100%", justifyContent: "center", alignItems: "center" }}>
      {recoverAccountState ? (
        <RecoverAccountComponent />
      ) : !firstLogin ? (
        <Stack height={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
            <img style={{ height: "35px" }} src="/img/drivi_logo.svg" alt="" />
            <Typography fontWeight={"300"} variant="subtitle1">
              Ingresa a tu cuenta
            </Typography>
          </Stack>
          <Stack gap={"20px"} sx={{ width: "fit-content", justifyContent: "center" }}>
            <form style={{ padding: "20px", width: "fit-content", display: "flex", flexDirection: "column", gap: "10px" }} onSubmit={handleSubmit(onSubmit)}>
              <TextField {...register("email")} sx={{ m: 1, width: "25ch" }} variant="standard" id="email" label="Email" type="email" />
              <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">Contraseña</InputLabel>
                <Input
                  {...register("password")}
                  id="standard-adornment-password"
                  type={showPasswordState ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleClickShowPassword}>
                        {showPasswordState ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Button disabled={loadingState} style={{ height: "38px", color: "white", marginTop: "30px", borderRadius: "0" }} width={"100%"} type="submit" variant="contained">
                  {!loadingState ? "Iniciar sesión" : <CircularProgress size={"20px"} />}
                </Button>
                <Button onClick={() => setRecoverAccountState(true)} style={{ marginTop: "5px", fontSize: "0.75rem" }}>
                  ¿Olvidaste tu contraseña?
                </Button>
              </FormControl>
            </form>
          </Stack>
        </Stack>
      ) : (
        <ChangePasswordComponent setFirstLogin={setFirstLogin} setFirstPasswordComponentState={setFirstPasswordComponentState} />
      )}
    </Stack>
  );
};

export default LoginPage;
