import React from "react";
import FadeInComponent from "./fadeIn.Component";
import MainContent from "./MainContent.component";
import { Container } from "@mui/material";

const ModalComponent = ({ children }) => {
  return (
    <FadeInComponent>
      <Container
        sx={{
          position: "fixed",
          minWidth: "100%",
          minHeight: "100vh",
          backdropFilter: "brightness(0.8)",
          zIndex: "10000",
          margin: "0!important",
          padding: "0!important",
          left: "0",
          right: "0",
          top: "0",
          bottom: "0",
        }}
      >
        <MainContent justifyContent={"center"}>{children}</MainContent>
      </Container>
    </FadeInComponent>
  );
};

export default ModalComponent;
