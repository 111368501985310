import "./App.css";
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { CircularProgress, Container } from '@mui/material';

import ProjectionPage from "./pages/projection/Projection.Page";
import ProjectionPageFake from "./pages/projection/Projection.Page.Fake.jsx";
import ClientsPage from "./pages/clients/Clients.Page.jsx";
import RepairshopPage from "./pages/repairshop/Repairshop.Page.jsx";
import SupportPage from "./pages/support/Support.Page.jsx";
import InterventionPage from "./pages/intervention/Intervention.Page.jsx";
import NewInterventionPage from "./pages/newIntervention/NewIntervention.Page.jsx";
import InterventionsPage from "./pages/interventions/Interventions.Page.jsx";
import LeadsPage from "./pages/leads/Leads.Page.jsx";
import VehiclePage from "./pages/vehicle/Vehicle.Page.jsx";
import ConfigurationPage from "./pages/configuration/Configuration.Page.jsx";
import NotFoundPage from "./pages/notFound/notFound.Page.jsx";

import Navbar from "./components/navbar/Navbar.jsx";
import TopNavbar from "./components/topNavbar/TopNavbar.Component.jsx";

import { useAtom } from "jotai";
import { adminAtom, tokenAtom, urlParam } from "./context/atoms/Atoms.Storage";
import LoginPage from "./pages/login/Login.Page.jsx";

import { getStorageObject } from "./utils/LocalStorage";
import { ValidateToken } from "./services/API/Auth.Services.js";

import { createTheme } from '@mui/material/styles';
import ToastComponent from "./components/toastComponent/toast.Component.jsx";
import RecoverAccountPage from "./pages/recoverAccount/RecoverAccount.Page.jsx";
import AdministratorPage from "./pages/administrator/Administrator.Page.jsx";
import { deleteIntervention } from "./services/API/Intervention.Services";
import { logout } from "./utils/SessionFunctions.js";

const theme_Style = createTheme({
  typography: {
    fontFamily: 'Inter, Arial, sans-serif'
  },
  palette: {
    primary: {
      main: "#ea5828",
      light: "#ffede7",
      dark: "#a33d1c"
    },
    secondary: {
      main: "#c3c3c3",
      lighter: "#f5f5f5",
      light: "#dedede",
      dark: "#6e6e6e"
    }
  },
  components: {
    MuiButton: {
      disableElevation: true,
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
})

function App() {

  const [jwtState, setJwtState] = useAtom(tokenAtom);
  const [admin, setAdmin] = useAtom(adminAtom);
  const [loadingState, setLoadingState] = useState(true);
  const [urlParamState, setUrlParamState] = useAtom(urlParam);

  const userData = getStorageObject("userData");

  const isAuthenticated = !!getStorageObject('token') && !getStorageObject('userData').firstLogin ? true : false;

  useEffect(() => {

    const queryString = window.location.search;
    const parametrosDaConsulta = queryString.replace(/^\?/, "");

    const parametros = new URLSearchParams(parametrosDaConsulta);
    const _id_repairshop = parametros.get("id0");
    const _id = parametros.get("id1");
    const _intervention = parametros.get("id2");
    const _flgClientProfiler = parametros.get("id3");
    const _token = parametros.get("id4");

    const userData = getStorageObject("userData");

    if (userData && !userData.version) {

      const adminSessionToken = getStorageObject("adminSessionToken");

      if (!adminSessionToken) { logout() }
    }

    if (ValidateToken(_token)) {
      const urlsParams = {
        id_repairshop: _id_repairshop,
        id: _id,
        intervention: _intervention,
        isClientProfiler: _flgClientProfiler
      };

      setJwtState(_token);
      setUrlParamState(urlsParams);

      const repairShopId = getStorageObject("repairShopId");
      const token = getStorageObject("token");

      if (token && repairShopId) {
        setLoadingState(false);
      }

      setLoadingState(false)
    }

  }, []);


  if (loadingState) {
    return (
      <Container sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress style={{ color: "primary.main" }} />
      </Container>
    );
  }

  return (
    <ThemeProvider theme={theme_Style}>
      <BrowserRouter>
        <ToastComponent />
        {isAuthenticated && <Navbar />}
        {isAuthenticated && <TopNavbar />}
        <Routes>
          <Route path="/leads" element={<LeadsPage />} />
          <Route path="/intervencion" element={<InterventionPage />} />
          <Route path="/recuperarcuenta" element={<RecoverAccountPage />} />
          {isAuthenticated ? (
            <>
              <Route path="/" element={<ClientsPage />} />
              <Route path="/clientes" element={<ClientsPage />} />
              <Route path="/vehiculo/:vehicleId" element={<VehiclePage />} />
              <Route path="/taller" element={<RepairshopPage />} />
              <Route path="/proyeccion" element={userData && userData.id_user_subscription === 1 ? <ProjectionPage /> : <ProjectionPageFake />} />
              <Route path="/soporte" element={<SupportPage />} />
              <Route path="/intervencion/crear" element={<NewInterventionPage />} />
              <Route path="/intervenciones" element={<InterventionsPage />} />
              <Route path="/configuracion" element={<ConfigurationPage />} />
              {(admin || getStorageObject("adminSessionToken")) && <Route path="/admin" element={<AdministratorPage />} />}
              <Route path="*" element={<NotFoundPage />} />
            </>
          ) : (
            <Route path="*" element={<LoginPage />} />
          )}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
