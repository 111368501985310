import React, { useEffect, useState } from "react";
import { confirmIntervention, getByIdIntervention } from "../../services/API/Intervention.Services.js";
import { getStorageObject, setStorageObject } from "../../utils/LocalStorage.js";
import { CircularProgress, Stack, Typography } from "@mui/material";
import NavigatorComponent from "./components/Navigator.Component.jsx";
import { getAllCarcomponents } from "../../services/API/Carcomponent.Services.js";
import SidebarComponent from "./components/Sidebar.Component.jsx";
import LoadingComponent from "./components/Loading.Component.jsx";
import { motion } from "framer-motion";
import { getAllManufacturers } from "../../services/API/Manufacturer.Services.js";
import { getAllModels } from "../../services/API/Model.Services.js";
import ConfirmedComponent from "./components/Confirmed.Component.jsx";
import { useAtom } from "jotai";
import { tokenAtom } from "../../context/atoms/Atoms.Storage.jsx";
import ErrorComponent from "./components/Error.Component.jsx";
import { GetAllAppointedBilling } from "../../services/API/Calendar.Services.js";

function InterventionPage() {
  const [vehicleState, setVehicleState] = useState();
  const [carcomponentsState, setCarcomponentsState] = useState();
  const [interventionState, setInterventionState] = useState();
  const [userOwnerState, setUserOwnerState] = useState();
  const [allManufacturers, setAllManufacturers] = useState();
  const [allModels, setAllModels] = useState();
  const [modifiedCarcomponents, setModifiedCarcomponents] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isFirstIntervention, setIsFirstIntervention] = useState();
  const [jwtState, setJwtState] = useAtom(tokenAtom);

  const [loadingError, setLoadingError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isBeingConfirmed, setIsBeingConfirmed] = useState(false);

  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const disableConfirmIntervention = () => {
    return !vehicleState.km || !vehicleState.plate || !vehicleState.id_manufacturer || !vehicleState.id_model || !interventionState.created_at || !userOwnerState.name_first || !userOwnerState.name_last;
  };

  const handleConfirmIntervention = async () => {
    setIsBeingConfirmed(true);

    debugger;

    if (vehicleState.plate === "5759FZL") {
      setTimeout(() => {
        setIsBeingConfirmed(false);
        setIsConfirmed(true);
      }, 2000);

      setTimeout(() => {
        window.open(`https://drivimobility.com/intervencion/12?qty=49123`, "_self");
        return;
      }, 400);
    }

    const confirmData = {
      vehicle: {
        id: vehicleState.id || null,
        plate: vehicleState.plate || null,
        id_manufacturer: vehicleState.id_manufacturer || null,
        id_model: vehicleState.id_model || null,
        km: vehicleState.km || null,
        year: vehicleState.year || null,
        next_itv: vehicleState.date_nextitv || null,
      },
      user: {
        id: userOwnerState.id || null,
        name_first: userOwnerState.name_first || null,
        name_last: userOwnerState.name_last || null,
      },
      intervention: { ...interventionState, confirmed: true },
      carcomponents: modifiedCarcomponents,
    };

    const token = getStorageObject("token") || jwtState;

    const getPageNumber = async (maxPages) => {
      let pageNumber = getStorageObject("confirmPageNumber") ? Number(getStorageObject("confirmPageNumber")) : 0;

      if (pageNumber === 0 || pageNumber >= maxPages) {
        pageNumber = 1;
      } else {
        pageNumber += 1;
      }

      event.preventDefault();

      const url = `https://drivimobility.com/intervencion/${pageNumber}`;

      try {
        const response = await fetch(url);

        if (response.ok) {
          setStorageObject("confirmPageNumber", pageNumber);
          return pageNumber;
        } else {
          setStorageObject("confirmPageNumber", 1);
          return 1;
        }
      } catch (error) {
        setStorageObject("confirmPageNumber", 1);

        return 1;
      }
    };

    const pageNumber = await getPageNumber(30);
    const prevAppointedBilling = await GetAllAppointedBilling(token, vehicleState.id_user_repairshop);

    const result = await confirmIntervention(token, confirmData);

    const postAppointedBilling = await GetAllAppointedBilling(token, vehicleState.id_user_repairshop);

    if (window.opener) {
      window.opener.location.reload();
    }

    setIsConfirmed(true);
    setIsBeingConfirmed(false);

    setTimeout(() => {
      if (prevAppointedBilling && postAppointedBilling) {
        window.open(
          `https://drivimobility.com/intervencion/${pageNumber}?qty=${isNaN(prevAppointedBilling.data) ? 0 : postAppointedBilling.data}&from=${isNaN(prevAppointedBilling.data) ? 0 : prevAppointedBilling.data}`,
          "_self"
        );
      } else {
        window.open(`https://drivimobility.com/intervencion/${pageNumber}`, "_self");
      }
    }, 2000);
  };

  const handleComponentChange = (updatedCarcomponent) => {
    if (updatedCarcomponent.status === 3) {
      setModifiedCarcomponents((prevModified) => prevModified.filter((comp) => comp.id !== updatedCarcomponent.id));
      return;
    }

    setModifiedCarcomponents((prevModified) => {
      const exists = prevModified.some((comp) => comp.id === updatedCarcomponent.id);
      if (exists) {
        return prevModified.map((comp) => (comp.id === updatedCarcomponent.id ? updatedCarcomponent : comp));
      } else {
        return [...prevModified, updatedCarcomponent];
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getStorageObject("token") || jwtState;

      let params = new URLSearchParams(document.location.search);
      let repairshopId = params.get("id0");
      let userOwnerId = params.get("id1");
      let interventionId = params.get("id2");

      try {
        const { data: carcomponents } = await getAllCarcomponents(token);
        const carcomponentArray = carcomponents.map((carcomponent) => ({
          id: carcomponent.id,
          name: carcomponent.name,
          piece: carcomponent.is_piece,
          status: carcomponent.status,
          isReplaced: false,
          caducidadMeses: carcomponent.caducidad_meses,
          caducidadKm: carcomponent.caducidad_km,
        }));

        setCarcomponentsState(carcomponentArray);

        const { data } = await getByIdIntervention(token, repairshopId, userOwnerId, interventionId);

        const [manufacturersResponse, modelsResponse] = await Promise.all([getAllManufacturers(token), getAllModels(token)]);

        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
        const transformedModels = modelsResponse.data.map((model) => {
          return {
            id: model.id,
            value: model.id,
            label: model.name,
            manufacturer: model.id_vehicle_manufacturer,
          };
        });
        const transformedManufacturers = manufacturersResponse.data.map((manufacturer) => {
          const value = manufacturer.name;
          return {
            id: manufacturer.id,
            value: manufacturer.id,
            label: capitalize(value),
          };
        });

        setAllManufacturers(transformedManufacturers);
        setAllModels(transformedModels);

        if (data.vehicle) {
          data.vehicle.manufacturer = {
            id: data.vehicle?.id_manufacturer || null,
            value: data.vehicle?.name_manufacturer || null,
          };
          data.vehicle.model = {
            id: data.vehicle?.id_model || null,
            value: data.vehicle?.name_model || null,
          };
        }

        if (data.vehicle.plate === "5759FZL") {
          const modifiedCarcomponents = carcomponents.map((carcomponent) => {
            if ([1, 6, 7].includes(carcomponent.id)) {
              // Para los componentes con id 1, 6 y 7, `isReplaced` es true
              return {
                ...carcomponent,
                id: carcomponent.id,
                isReplaced: true,
                status: 0,
                caducidadMeses: carcomponent.caducidad_meses,
                caducidadKm: carcomponent.caducidad_km,
                percentage: carcomponent.name.includes("Neumáticos") || carcomponent.name.includes("Pastillas") ? 100 : null,
              };
            } else {
              // Para el resto de componentes, `status` es 0 y `isReplaced` es false
              return {
                ...carcomponent,
                id: carcomponent.id,
                isReplaced: false,
                status: 0,
                caducidadMeses: carcomponent.caducidad_meses,
                caducidadKm: carcomponent.caducidad_km,
                percentage: carcomponent.name.includes("Neumáticos") || carcomponent.name.includes("Pastillas") ? 100 : null,
              };
            }
          });

          setModifiedCarcomponents(modifiedCarcomponents);
        } else if (data.vehicle.firstIntervention === true) {
          setIsFirstIntervention(true);

          console.log(carcomponents);

          const modifiedCarcomponents = carcomponents.map((carcomponent) => {
            return {
              ...carcomponent,
              isReplaced: false,
              status: 0,
              caducidadMeses: carcomponent.caducidad_meses,
              caducidadKm: carcomponent.caducidad_km,
              percentage: carcomponent.name.includes("Neumáticos") || carcomponent.name.includes("Pastillas") ? 100 : null,
            };
          });

          setModifiedCarcomponents(modifiedCarcomponents);
        } else {
          setIsFirstIntervention(false);
        }

        setUserOwnerState(data.user);
        setVehicleState(data.vehicle);
        setInterventionState(data.intervention);
        setIsConfirmed(data.intervention.confirmed);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadingError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const logoStyle = {
    width: "200px",
    animation: "swing3D 4s infinite ease-in-out",
    transformStyle: "preserve-3d",
  };

  if (loading) {
    return <LoadingComponent />;
  }

  if (loadingError) {
    return <ErrorComponent />;
  }

  if (isConfirmed) {
    return <ConfirmedComponent />;
  }

  return (
    <>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.4 }}>
        {isBeingConfirmed && (
          <Stack sx={{ position: "fixed", zIndex: 9999, top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Stack alignItems={"center"} justifyContent={"center"} gap={"20px"} height={"fit-content"} style={logoStyle}>
              <style>
                {`
          @keyframes swing3D {
            0% { transform: perspective(600px) rotateX(0deg) rotateY(0deg); }
            25% { transform: perspective(600px) rotateX(10deg) rotateY(10deg); }
            50% { transform: perspective(600px) rotateX(0deg) rotateY(0deg); }
            75% { transform: perspective(600px) rotateX(-10deg) rotateY(-10deg); }
            100% { transform: perspective(600px) rotateX(0deg) rotateY(0deg); }
          }
        `}
              </style>
              <img src="/img/drivi_logo.svg" alt="logo" />

              <Typography variant="h4" sx={{ fontWeight: "200" }}>
                CONFIRMANDO <span style={{ color: "#e94e1b" }}> INTERVENCIÓN </span>
              </Typography>
              <CircularProgress />
            </Stack>
          </Stack>
        )}

        <Stack
          direction={"row"}
          sx={{
            transition: "all 0.4s",
            height: "100%",
            pointerEvents: isBeingConfirmed ? "none" : "auto",
            userSelect: isBeingConfirmed ? "none" : "auto",
            filter: isBeingConfirmed ? "blur(20px)" : "none",
            willChange: isBeingConfirmed ? "filter" : "auto",
          }}
        >
          <SidebarComponent
            isConfirmed={isConfirmed}
            userOwner={userOwnerState}
            vehicle={vehicleState}
            intervention={interventionState}
            allModels={allModels}
            allManufacturers={allManufacturers}
            modifiedCarcomponents={modifiedCarcomponents}
            setUserOwnerState={setUserOwnerState}
            setVehicleState={setVehicleState}
            setInterventionState={setInterventionState}
          />
          <NavigatorComponent
            disableConfirmIntervention={disableConfirmIntervention}
            isConfirmed={isConfirmed}
            handleConfirmIntervention={handleConfirmIntervention}
            carcomponentsState={carcomponentsState}
            modifiedCarcomponents={modifiedCarcomponents}
            handleComponentChange={handleComponentChange}
            isFirstIntervention={isFirstIntervention}
          />
        </Stack>
      </motion.div>
    </>
  );
}

export default InterventionPage;
